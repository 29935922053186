import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { get } from 'lodash';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { CmsEntities, CmsModel, bindToContent } from 'client/data/models/cms';
import { buildPubStateModelYearPath, VehicleModel } from 'client/data/models/vehicle';
import { getPageLinksPath, LinkWidgetsModel } from 'client/data/models/link-widgets';
import { DEFAULT_CONTENT, parseContent } from 'client/data/cms/content';
import { getMeta, getSeoImage } from 'site-modules/shared/utils/seo-helper';
import { getFAQSchema } from 'client/seo/schemas/faq';
import { getQAEntriesFromContent } from 'client/site-modules/shared/utils/faq';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getBaseBreadcrumb, getHomeBreadcrumb } from 'site-modules/shared/components/breadcrumbs/helper';
import { SearchByModule } from 'site-modules/shared/components/search-by-module/search-by-module';
import { AppraisalInfoSection } from 'site-modules/appraisal/components/appraisal-info-section/appraisal-info-section';
import { TrackingHandler } from 'client/tracking/handler';
import { AppraisalMap, MMZ_TRACKING_IDS } from 'client/tracking/maps/appraisal';
import { PageLinksWidget } from 'site-modules/shared/components/page-links-widget/page-links-widget';
import { URL_PATHS } from 'site-modules/shared/constants/appraisal/paths';
import { AppraisalTabsContainer } from 'site-modules/shared/components/appraisal/appraisal-tabs-container/appraisal-tabs-container';
import { SuggestedVehiclePricingCarouselContainer } from 'site-modules/shared/components/suggested-vehicle-pricing-carousel/suggested-vehicle-pricing-carousel-container';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { setCriticalCss } from 'client/actions/critical-css';
import { PUB_STATES } from 'client/constants/pub-states';
import { isAddressToVinExcludedState } from 'client/site-modules/shared/utils/appraisal/appraisal-tabs';
import { AppraisalLandingHeadingSection } from 'site-modules/appraisal/components/appraisal-landing-heading-section/appraisal-landing-heading-section';
import { getIsOptimizedTraffic } from 'site-modules/shared/utils/appraisal/optimized-traffic';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import 'site-modules/shared/components/appraisal/appraisal-common.scss';
import './appraisal.scss';
import './appraisal-landing.scss';

export const PAGE_DISPLAY_NAME = 'Appraise Your Car';
export const APPRAISAL_CONTENT_FILE_PATH = 'research/appraisal/index';

const getIsEmo2717Eligible = ({ isMobile, stateCode }) => Boolean(!isAddressToVinExcludedState(stateCode) && isMobile);

export class AppraisalUI extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    isAppExtensionPage: PropTypes.bool,
    content: CmsEntities.Content,
    location: PropTypes.shape({}),
    stateCode: PropTypes.string,
  };

  static defaultProps = {
    isMobile: false,
    isAppExtensionPage: false,
    content: DEFAULT_CONTENT,
    location: {},
    stateCode: '',
  };

  state = {
    make: null,
    model: null,
    year: null,
    validated: false,
    isEstimate: false,
    isUtmAccountAppraisal: false,
    isUtmSourceFacebook: false,
  };

  componentDidMount() {
    TrackingHandler.useMap(AppraisalMap);
  }

  renderAppraisalTabsContainer = (additionalProps = {}) => {
    const { isMobile, isAppExtensionPage, stateCode, location } = this.props;
    const isOptimizedFunnelTraffic = getIsOptimizedTraffic({ isAppExtensionPage, location });
    const renderAddressTab = !isAddressToVinExcludedState(stateCode);

    return (
      <AppraisalTabsContainer
        isBackgroundStyleTabEnabled
        isFullWidth
        isMobile={isMobile}
        isAppExtensionPage={isAppExtensionPage}
        className={classnames({
          'mb-0_5': !isOptimizedFunnelTraffic,
          'mb-2': isOptimizedFunnelTraffic,
        })}
        isProfileOfferVinPrepopulationEnabled
        hasAddressToVinDrawer={!(isAddressToVinExcludedState(stateCode) || isOptimizedFunnelTraffic)}
        isAppraisalLanding
        ctaBtnColor="blue-50"
        renderAddressTab={renderAddressTab}
        {...additionalProps}
      />
    );
  };

  renderAppraisalTabsContainerEmo2507 = additionalProps => (
    <Experiment name="EMO-2507" showDefault>
      <Recipe name="ctrl" isDefault>
        {this.renderAppraisalTabsContainer({ ...additionalProps, emo2507Recipe: 'ctrl' })}
      </Recipe>
      <Recipe name="chal0">{this.renderAppraisalTabsContainer({ ...additionalProps, emo2507Recipe: 'ctrl' })}</Recipe>
      <Recipe name="chal1">{this.renderAppraisalTabsContainer({ ...additionalProps, emo2507Recipe: 'chal1' })}</Recipe>
      <Recipe name="chal2">{this.renderAppraisalTabsContainer({ ...additionalProps, emo2507Recipe: 'chal2' })}</Recipe>
    </Experiment>
  );

  renderAppraisalTabsContainerEmo2717 = additionalProps => {
    const { stateCode, isMobile } = this.props;
    const isEmo2717Eligible = getIsEmo2717Eligible({ stateCode, isMobile });

    if (!isEmo2717Eligible) return this.renderAppraisalTabsContainerEmo2507(additionalProps);

    return (
      <Experiment name="EMO-2717" showDefault>
        <Recipe name="ctrl" isDefault>
          {this.renderAppraisalTabsContainerEmo2507(additionalProps)}
        </Recipe>
        <Recipe name="chal0">{this.renderAppraisalTabsContainerEmo2507(additionalProps)}</Recipe>
        <Recipe name="chal1">
          {this.renderAppraisalTabsContainerEmo2507({ ...additionalProps, isEmo2717Chal: true })}
        </Recipe>
      </Experiment>
    );
  };

  render() {
    const { content, isMobile, isAppExtensionPage, location } = this.props;
    const title = get(content, 'title', PAGE_DISPLAY_NAME);
    const subheader = content.child('html').content || '';
    const isOptimizedFunnelTraffic = getIsOptimizedTraffic({ isAppExtensionPage, location });
    const breadcrumbs = [getHomeBreadcrumb(), getBaseBreadcrumb(PAGE_DISPLAY_NAME, URL_PATHS.APPRAISAL_LANDING)];

    return (
      <main id="main-content" className="appraisal appraisal-landing" data-tracking-parent="appraisal-landing-page">
        <AppraisalLandingHeadingSection
          title={title}
          subheader={subheader}
          breadcrumbs={breadcrumbs}
          renderAppraisalTabs={this.renderAppraisalTabsContainerEmo2717}
          isAppExtensionPage={isAppExtensionPage}
        />
        <Container>
          <Row>
            <Col xs={12} lg={10}>
              {!isOptimizedFunnelTraffic && (
                <div className="mt-2">
                  <ErrorBoundary>
                    <AppraisalInfoSection
                      howItWorksContent={content.child('how-it-works')}
                      articlesContent={content.child('articles')}
                      faqContent={content.child('faq')}
                      isMobile={isMobile}
                      pathname={location.pathname}
                    />
                  </ErrorBoundary>
                  <hr className="mt-1_5 mb-2" />
                  <section>
                    <h2 className="heading-3 mb-0">Shop used cars</h2>
                    <ErrorBoundary>
                      <SearchByModule
                        location={location}
                        isMobile={isMobile}
                        trackingMap={MMZ_TRACKING_IDS}
                        isUsed
                        isSmallContainer={!isMobile}
                        contentClassName="bg-white"
                      />
                    </ErrorBoundary>
                  </section>
                </div>
              )}
            </Col>
          </Row>
          {!isOptimizedFunnelTraffic && (
            <Fragment>
              <hr className="mt-1_5 mb-2" />
              <MinHeightWrapper
                className="suggested-pricing-wrapper show-separator"
                componentType="SUGGESTED_PRICING_CAROUSEL"
              >
                <SuggestedVehiclePricingCarouselContainer className="px-0" />
              </MinHeightWrapper>
              <PageLinksWidget divideSections={false} title="Related information" hasTopDivider />
            </Fragment>
          )}
        </Container>
      </main>
    );
  }
}

export const mapStateToProps = state => ({
  isMobile: state.mobile,
  location: get(state, 'pageContext.location'),
  isAppExtensionPage: get(state, 'pageContext.isAppExtensionPage'),
  isAuthenticated: get(state, 'profile.isAuthenticated'),
  stateCode: get(state, 'visitor.location.stateCode'),
});

export const stateToPropsConfig = {
  content: bindToContent(APPRAISAL_CONTENT_FILE_PATH),
};

export const Appraisal = connect(mapStateToProps)(connectToModel(AppraisalUI, stateToPropsConfig));

export const pageDefinitionConfiguration = {
  name: 'used_cars_tmv_appraiser',
  category: 'used_cars_tmv_appraiser',
  preload: store => {
    const state = store.getState();
    const { pageContext, mobile: isMobile } = state;
    const stateCode = get(state, 'visitor.location.stateCode');
    const preloader = new ModelPreloader(store);

    const location = get(pageContext, 'location');
    const isAppExtensionPage = get(pageContext, 'isAppExtensionPage');
    preloader.resolve(`content["${APPRAISAL_CONTENT_FILE_PATH}"]`, CmsModel);
    preloader.resolve(getPageLinksPath(), LinkWidgetsModel);
    preloader.resolve(buildPubStateModelYearPath([PUB_STATES.NEW_USED, PUB_STATES.USED]), VehicleModel);

    return preloader.load().then(() => {
      const isOptimizedFunnelTraffic = getIsOptimizedTraffic({ location, isAppExtensionPage });

      const emo2717Recipe = ExperimentUtil.getForcedOrAssignedRecipeName({
        state: store.getState(),
        campaignName: 'EMO-2717',
        defaultVal: 'ctrl',
      });
      const isEmo2717Chal = emo2717Recipe === 'chal1' && getIsEmo2717Eligible({ isMobile, stateCode });

      store.dispatch(
        setCriticalCss({
          isOptimizedFunnelTraffic, // 2 values
          isAppExtensionPage: pageContext.isAppExtensionPage, // 2 values
          isEmo2717Chal, // 2 values
        })
      );
    });
  },
  seo: (state, props) => {
    const modelState = getModelState(state);
    const content = modelState.get(`content["${APPRAISAL_CONTENT_FILE_PATH}"]`, CmsModel);
    const parsedContent = parseContent(content);
    return {
      ...getMeta(parsedContent),
      image: getSeoImage(parsedContent),
      jsonld: [
        getFAQSchema({
          entries: getQAEntriesFromContent(parsedContent.child('faq')),
          generateLearnMoreLinks: true,
          pathname: get(props, 'location.pathname'),
        }),
        getBreadcrumbSchema([getHomeBreadcrumb(), getBaseBreadcrumb(PAGE_DISPLAY_NAME, URL_PATHS.APPRAISAL_LANDING)]),
      ],
    };
  },
  component: Appraisal,
};

export default pageDefinition(pageDefinitionConfiguration);
